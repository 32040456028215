function DashboardPBI() {
  return (
    <div className="h-full min-w-full">
      <iframe
        title="PowerBI Dashboard Task"
        src="https://app.powerbi.com/view?r=eyJrIjoiNTU3N2ZkMmEtMzJhZS00YjcxLWE1YTktY2ZlMTBmZjBlZjdmIiwidCI6IjdlYzdjZGQ2LTI2Y2MtNGIwMS1iOTA3LTI5MzllZGI5Y2Y1MyJ9"
        frameborder="0"
        className="h-[90vh] w-full"
      />
    </div>
  );
}
export default DashboardPBI;
