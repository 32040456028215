// Admin Imports
import MainDashboard from "views/admin/default";

// Auth Imports

// Icon Imports
import ProjectsList from "views/admin/ceo/ProjectsList";
import Project from "components/icons/Project";
import ProjectDetails from "views/admin/ceo/ProjectDetails";
import HomeIcon from "components/icons/HomeIcon";
import UsersList from "views/admin/default/UsersList";
import UserIcon from "components/icons/UserIcon";
import CeoDashboard from "views/admin/ceo";
import TLDashboard from "views/admin/team-leader";
import ProjectsListTL from "views/admin/team-leader/ProjectsList";
import ProjectDetailsTL from "views/admin/team-leader/ProjectDetails";
import ExpertProjectsList from "views/admin/expert/ProjectsList";
import HODashboard from "views/admin/HO";
import HOProjectsList from "views/admin/HO/ProjectsList";
import DepartmentsList from "views/admin/ceo/DepartmentsList";
import DepartmentsListTL from "views/admin/team-leader/DepartmentsList";
import MinistryDashboard from "views/admin/ministry";
import TasksList from "views/admin/ministry/TasksList";
import Assignment from "views/admin/ministry/Assignment";
import AssignmentIndv from "views/admin/assinment-individuals/Assignment";
import DashboardPBI from "views/admin/ministry/DashboardPBI";

const routes = [
  {
    name: "Home",
    layout: "/admin",
    path: "home",
    icon: <HomeIcon />,
    component: <MainDashboard />,
  },

  {
    name: "User Management",
    layout: "/admin",
    icon: <UserIcon />,
    path: "users",
    component: <UsersList />,
  },
  // {
  //   name: "Base Data",
  //   layout: "/admin",
  //   path: "base-data",
  //   icon: <BaseDataIcon />,
  //   component: <BaseData />,
  // },
];

export const ceoRoutes = [
  {
    name: "Home",
    title: "Home",
    layout: "/ceo",
    path: "home",
    icon: <HomeIcon />,
    component: <CeoDashboard />,
  },

  {
    name: "Tasks",
    title: "Tasks",
    layout: "/ceo",
    icon: <Project extra="h-6 w-6 fill-white" />,
    path: "projects",
    component: <ProjectsList />,
    children: [
      {
        name: "Project Details",
        layout: "/ceo",
        icon: <Project extra="h-6 w-6 fill-white" />,
        path: ":id",
        component: <ProjectDetails />,
      },
    ],
  },

  {
    name: "Committee",
    title: "Committee",
    layout: "/ceo",
    path: "commitee",
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="inherit"
        stroke="inherit"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M12 7c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zm0 8c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3z" />
      </svg>
    ),
    component: <DepartmentsList />,
  },
];
export const ministryRoutes = [
  {
    name: "Home",
    title: "Home",
    layout: "/ministry",
    path: "home",
    icon: <HomeIcon />,
    component: <MinistryDashboard />,
  },

  {
    name: "Assignments",
    title: "Assignments",
    layout: "/ministry",
    icon: <Project extra="h-6 w-6 fill-white" />,
    path: "assignments",
    component: <Assignment />,
  },
  {
    name: "Tasks",
    title: "Tasks",
    layout: "/ministry",
    icon: <Project extra="h-6 w-6 fill-white" />,
    path: "projects",
    component: <TasksList />,
  },
  {
    name: "Dashboard",
    title: "Dashboard",
    layout: "/ministry",
    icon: <Project extra="h-6 w-6 fill-white" />,
    path: "dashboard",
    component: <DashboardPBI />,
  },
];
export const assinmentIndividualsRoutes = [
  {
    name: "Assignments",
    title: "Assignments",
    layout: "/assinment-individuals",
    icon: <Project extra="h-6 w-6 fill-white" />,
    path: "assignments",
    component: <AssignmentIndv />,
  },
];

export const teamLeaderRoutes = [
  {
    name: "Home",
    layout: "/team-leader",
    path: "home",
    icon: <HomeIcon />,
    component: <TLDashboard />,
  },

  {
    name: "Tasks",
    layout: "/team-leader",
    icon: <Project extra="h-6 w-6 fill-white" />,
    path: "projects",
    component: <ProjectsListTL />,
    children: [
      {
        name: "Project Details",
        layout: "/team-leader",
        icon: <Project extra="h-6 w-6 fill-white" />,
        path: ":id",
        component: <ProjectDetailsTL />,
      },
    ],
  },
  {
    name: "Committee",
    title: "Committee",
    layout: "/team-leader",
    path: "commitee",
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="inherit"
        stroke="inherit"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M12 7c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zm0 8c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3z" />
      </svg>
    ),
    component: <DepartmentsListTL />,
  },
];

export const expertRoutes = [
  // {
  //   name: "Home",
  //   layout: "/expert",
  //   path: "home",
  //   icon: <HomeIcon />,
  //   component: <ExpertDashboard />,
  // },

  {
    name: "Tasks",
    layout: "/expert",
    icon: <Project extra="h-6 w-6 fill-white" />,
    path: "projects",
    component: <ExpertProjectsList />,
  },
];

export const higherOfficialRoutes = [
  {
    name: "Home",
    layout: "/higher-official",
    path: "home",
    icon: <HomeIcon />,
    component: <HODashboard />,
  },
  {
    name: "Tasks",
    layout: "/higher-official",
    path: "projects",
    icon: <Project extra="h-6 w-6 fill-white" />,
    component: <HOProjectsList />,
  },
];

export default routes;
