import { configure } from "axios-hooks";
import Axios from "axios";

const axios = Axios.create({
  baseURL: process.env.REACT_APP_BASE_URL, //8005
  headers: {
    "Content-Type": "application/json",
  },
});

// Add a token to the header using axios interceptors
axios.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `JWT ${token}`;
  }
  return config;
});

axios.interceptors.response.use(
  (response) => {
    // console.log("response", response);
    return response;
  },
  async (error) => {
    const refreshToken = localStorage.getItem("refreshToken");
    if (error?.response?.status === 401) {
      if (refreshToken) {
        try {
          const refreshResult = await axios.post("/api/v1/jwt/refresh/", {
            refresh: refreshToken,
          });
          if (refreshResult.data) {
            localStorage.setItem("token", refreshResult.data.access);
            return await axios(error.config);
          }
        } catch (error) {
          console.log(error);
          localStorage.removeItem("token");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("user");
          window.location.href = "/auth/sign-in";
        }

        window.location.href = "/auth/sign-in";
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);

configure({ axios });
